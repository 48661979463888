import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { QueryGetUsers, QueryGetUser, MutationDeleteUser } from './graphql'

export const getUsers = async ({ first = 10, after = null }: { first: null | number, after?: null | string }) => {
  const response: any = await API.graphql({
    query: QueryGetUsers,
    variables: { first, after },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    users: response.data.getUsers.items,
    nextToken: response.data.getUsers.nextToken,
  }
}

export const getUser = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: QueryGetUser,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    user: response.data.getUser,
  }
}

export const deleteUser = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: MutationDeleteUser,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    user: response.data.deleteUser,
  }
}
