import { Dispatch } from 'redux'
import { Actions as UserActions } from '../stores/user/actions'
import { RootState } from '../stores/reducers'
import { connect, ConnectedProps } from 'react-redux'
import Settings from './Settings'
import { UpdateCurrentUserInput } from '@/stores/user/interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (payload: UpdateCurrentUserInput) => dispatch(UserActions.updateCurrentUser.started(payload))
})

const mapStateToProps = (state: RootState) => ({
  user: { ...state.user },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ContainerProps = ConnectedProps<typeof connector>

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
