import React, { useEffect, useMemo, useState } from 'react'
import { ContainerProps } from './Users.connect'
import { Link } from 'react-router-dom'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { debounce } from 'lodash-es'

const Users: React.FC<ContainerProps> = ({ getUsers, searchUsers, users }) => {
  useEffect(() => {
    getUsers({ after: null })
  }, [])

  const [keyword, setKeyword] = useState('')
  const handleSearch = debounce((value: string)=> {
    setKeyword(value)
  }, 300)

  const filteredUsers = useMemo(() => {
    if (keyword === '') {
      return users.data
    } else {
      return users.data.filter(({ nickname }) => ~nickname.indexOf(keyword))
    }
  }, [users, keyword])

  const onNext = () => {
    getUsers({ after: users.nextToken })
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress/>
    </div>
  )

  return (
    <div>
      <div className="flex justify-end m-10">
        <div className="flex items-center px-4 py-2 bg-white shadow rounded">
          <Search className="mr-2"/>
          <TextField placeholder="ユーザー検索" onChange={event => handleSearch(event.target.value)}/>
        </div>
      </div>
      <div className="m-10 bg-white shadow rounded">
        <h2 className="px-6 py-4 text-xl">ユーザー一覧</h2>
        <table className="border-collapse w-full">
          <thead className="border-b border-gray-400">
            <tr className="w-full">
              <th className="px-4 py-2 text-left text-xs font-normal text-gray-600">
                ユーザー名
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-400 border-b border-gray-400">
            {filteredUsers.map(user => (
              <tr key={user.id} className="w-full">
                <td className="px-4 py-3 text-sm">{user.nickname}</td>
                <td className="w-24 px-4 py-3 text-sm">
                  <Link to={`user/${user.id}/logs`} className="text-primary">詳細</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!users.fetching && users.nextToken &&
          <div className="flex justify-center p-4">
            <Button onClick={onNext} variant="outlined" color="primary">もっと見る</Button>
          </div>
        }
        {users.fetching && renderProgress()}
      </div>
    </div>
  )
}

export default Users
