const WEATHERS = {
  SUN: {
    value: 'SUNNY',
    label: '晴れ',
    color: '#FCB887',
  },
  CLOUD: {
    value: 'CLOUD',
    label: '曇り',
    color: '#8CDEF8',
  },
  THUNDER: {
    value: 'THUNDER',
    label: '雷',
    color: '#F4D573',
  },
  TYPHOON: {
    value: 'TYPHOON',
    label: '台風',
    color: '#AAC6BF',
  },
  RAIN: {
    value: 'RAIN',
    label: '雨',
    color: '#7FA9FC',
  },
  SNOW: {
    value: 'SNOW',
    label: '雪',
    color: '#CBC2D4',
  },
}

export default WEATHERS
