import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { QueryGetLogs, QueryGetLog, MutationCreateLog, MutationUpdateLog, MutationDeleteLog } from './graphql'
import { CreateLogInput, UpdateLogInput } from './interface'

export const getLogs = async ({ username = '' }: { username: string }) => {
  const response: any = await API.graphql({
    query: QueryGetLogs,
    variables: { username },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    logs: response.data.getForecastListByUser.items,
    nextToken: response.data.getForecastListByUser.nextToken,
  }
}

export const getLog = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: QueryGetLog,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    log: response.data.getForecast,
  }
}

export const createLog = async ({ input }: CreateLogInput) => {
  const response: any = await API.graphql({
    query: MutationCreateLog,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    log: response.data.createForecast,
  }
}

export const updateLog = async ({ input }: UpdateLogInput) => {
  const response: any = await API.graphql({
    query: MutationUpdateLog,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    log: response.data.updateForecast,
  }
}

export const deleteLog = async ({ id }: { id: string }) => {
  const response: any = await API.graphql({
    query: MutationDeleteLog,
    variables: { id },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })

  return {
    log: response.data.deleteForecast,
  }
}
