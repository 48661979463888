export const QueryGetUsers = `query getUser($first: Int, $after: String) {
  getUsers(first: $first, after: $after) {
    items {
      id
      email
      nickname
    }
    nextToken
  }
}`

export const QueryGetUser = `query getUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    nickname
  }
}`

export const MutationDeleteUser = `mutation DeleteUser($id: ID!) {
  deleteUser(id: $id){
    id
    email
    nickname
  }
}`
