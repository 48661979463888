import { actionCreatorFactory } from 'typescript-fsa'
import {
  GET_USERS,
  SEARCH_USERS
} from './types'
import {
  getUsersInput,
  getUsersOutput,
  SearchUsersInput,
  SearchUsersOutput
} from './interface'

const actionCreator = actionCreatorFactory('USERS')

export const getUsers = actionCreator.async<getUsersInput, getUsersOutput>(GET_USERS)
export const searchUsers = actionCreator.async<SearchUsersInput, SearchUsersOutput>(SEARCH_USERS)
