import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as userActions from './actions'
import { LogsState } from './interface'

const initialState: LogsState = {
  data: {},
  fetching: false,
  error: null
}

export const logsReducer = reducerWithInitialState<LogsState>(initialState)
  // Get logs
  .case(userActions.getLogs.started, (state, payload) => {
    return { ...state, fetching: true, error: null }
  })
  .case(userActions.getLogs.done, (state, payload) => {
    const data = {
      ...state.data,
      [payload.params.userId]: [...payload.result.logs]
    }
    return { ...state, fetching: false, data, error: null }
  })
  .case(userActions.getLogs.failed, (state, payload) => {
    return { ...state, fetching: false, error: { ...payload.error } }
  })
