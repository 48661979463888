import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { connector, ContainerProps } from './restore.container'
import * as paths from '../../constants/paths'

type Inputs = {
  email: string
}

const Restore: React.FC<RouteComponentProps & ContainerProps> = (props) => {
  const [processing, setProcessing] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)

  const { register, handleSubmit, errors } = useForm<Inputs>()

  const onSubmit = async (data: Inputs) => {
    setProcessing(true)
    props.restore(data.email, onSubmitSuccess, onSubmitError)
  }

  const onSubmitSuccess = (email: string) => {
    props.history.push(
      `${paths.RESET_PASSWORD}?email=${encodeURIComponent(email)}`
    )
  }

  const onSubmitError = (error: Error) => {
    setErrorMsg(error.message)
    setProcessing(false)
  }

  return (
    <div className="w-full max-w-sm m-auto pt-6 pb-12 px-8 shadow-colored">
      <h1 className="text-2xl text-center">パスワード再発行</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <TextField
            name="email"
            type="email"
            inputRef={register({
              required: 'メールアドレスを入力してください',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: '無効なメールアドレスです',
              },
            })}
            className="w-full"
            label="メールアドレス"
            helperText={!!errors.email && errors.email.message}
            error={!!errors.email}
          />
        </div>

        {errorMsg && (
          <div style={{ padding: '16px 16px 0', fontSize: 12, color: 'red' }}>
            {errorMsg}
          </div>
        )}

        <div className="mt-12">
          <Button
            type="submit"
            className="w-full"
            variant="contained"
            color="primary"
            disabled={processing}
          >
            送信
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withRouter(connector(Restore))
