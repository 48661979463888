import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Actions } from './actions'
import { UserState, User } from './interface'

const initialState: UserState = {
  data: null,
  fetched: false,
  error: null,
}

export const userReducer = reducerWithInitialState<UserState>(initialState)
  // Sign In
  .case(Actions.signIn.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.signIn.done, (state, payload) => {
    return { ...state, data: { ...payload.result.user }, error: null }
  })
  .case(Actions.signIn.failed, (state, payload) => {
    return { ...state, error: { ...payload.error } }
  })

  // Sign Out
  .case(Actions.signOut.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.signOut.done, (state, payload) => {
    return { ...state, data: null, error: null }
  })
  .case(Actions.signOut.failed, (state, error) => {
    return { ...state, error }
  })

  // Get Current user
  .case(Actions.getCurrentUser.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.getCurrentUser.done, (state, payload) => {
    return {
      ...state,
      fetched: true,
      data: { ...payload.result.user },
      error: null,
    }
  })
  .case(Actions.getCurrentUser.failed, (state, error) => {
    return { ...state, fetched: true, error }
  })

  // Update Current user
  .case(Actions.updateCurrentUser.started, (state, payload) => {
    return { ...state, error: null }
  })
  .case(Actions.updateCurrentUser.done, (state, payload) => {
    const updatedUser = { ...state.data, ...payload.params.attributes }
    return { ...state, data: updatedUser as User, error: null }
  })
  .case(Actions.updateCurrentUser.failed, (state, error) => {
    return { ...state, fetched: true, error }
  })

  // Reset Password
  .case(Actions.resetPassword.started, (state, email) => {
    return { ...state }
  })
  .case(Actions.resetPassword.done, (state, payload) => {
    return { ...state, ...payload.result, signup: true, error: null }
  })
  .case(Actions.resetPassword.failed, (state, error) => {
    return { ...state, error }
  })
