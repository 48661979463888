import { actionCreatorFactory } from 'typescript-fsa'
import {
  GET_LOGS,
  GET_LOG,
  CREATE_LOG,
  UPDATE_LOG,
} from './types'
import {
  GetLogsInput,
  GetLogsOutput,
  GetLogInput,
  GetLogOutput,
  CreateLogInput,
  CreateLogOutput,
  UpdateLogInput,
  UpdateLogOutput,
} from './interface'

const actionCreator = actionCreatorFactory('LOGS')

export const getLogs = actionCreator.async<GetLogsInput, GetLogsOutput>(GET_LOGS)
export const getLog = actionCreator.async<GetLogInput, GetLogOutput>(GET_LOG)
export const createLog = actionCreator.async<CreateLogInput, CreateLogOutput>(CREATE_LOG)
export const updateLog = actionCreator.async<UpdateLogInput, UpdateLogOutput>(UPDATE_LOG)
