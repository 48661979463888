import React, { useState, useEffect, useMemo, useContext } from 'react'
import { ContainerProps } from './Logs.connect'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import dayjs from 'dayjs'
import { getUser, deleteUser } from '@/stores/users/api'
import { User } from '@/stores/users/interface'
import { SnackbarContext } from '@/components/Snackbar'
import * as PATHS from '@/constants/paths'

const Logs: React.FC<ContainerProps & RouteComponentProps<{ userId: string }>> = ({ getLogs, getUsers, history, match, logs, users }) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const userId = useMemo(() => match.params.userId, [match])
  const userLogs = useMemo(() => logs.data[userId] || [], [userId, logs])

  const [user, setUser] = useState<User | null>(null)
  const fetchUser = async (id: string) => {
    const fetched = await getUser({ id: userId })
    setUser(fetched.user)
  }

  useEffect(() => {
    fetchUser(userId)
    getLogs(userId)
  }, [])

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress/>
    </div>
  )

  const handleDeleteUser = async () => {
    try {
      await deleteUser({ id: userId as string })
      openSnackbar({ type: 'success', message: `ユーザー'${user?.nickname}'を削除しました` })
      history.push(PATHS.DASHBOARD)
    } catch (error) {
      console.error(error)
      openSnackbar({ type: 'error', message: 'エラーが発生しました' })
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center p-12 bg-white shadow">
        <h2 className="text-2xl">{user?.nickname}</h2>
        <div className="flex gap-4">
          <Button onClick={handleDeleteUser}　color="primary">このユーザーを削除</Button>
          <Button className="ml-4" variant="contained" color="primary">
            <Link to={`/user/${userId}/log/create`}>新規ログ登録</Link>
          </Button>
        </div>
      </div>
      <div className="m-10 bg-white shadow rounded">
        <h2 className="px-6 py-4 text-xl">ログ一覧</h2>
        <table className="border-collapse w-full">
          <thead className="border-b border-gray-400">
            <tr className="w-full">
              <th className="px-4 py-2 text-left text-xs font-normal text-gray-600">
                ログ登録日時
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-400">
            {userLogs.map(log => (
              <tr key={log.id} className="w-full">
                <td className="w-full px-4 py-3 text-sm">{dayjs(log.createdAt).format('YYYY/MM/DD HH:mm')}</td>
                <td className="px-4 py-3 text-sm">
                  <Link to={`/user/${userId}/log/${log.id}/edit`} className="text-gray-700">
                    <Edit/>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!logs.fetching && !userLogs.length && (
          <div className="p-10 text-center text-gray-700">
            <p>ログはありません</p>
          </div>
        )}
        {logs.fetching && renderProgress()}
      </div>
    </div>
  )
}

export default withRouter(Logs)
