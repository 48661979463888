import { actionCreatorFactory } from 'typescript-fsa'
import { SIGN_IN, SIGN_OUT, GET_CURRENT_USER, UPDATE_CURRENT_USER, RESET_PASSWORD } from './types'
import {
  SignInActionInput,
  SignInActionOutput,
  SignOutActionInput,
  getCurrentUserOutput,
  UpdateCurrentUserInput,
  ResetPasswordActionPayload,
  IResetPassword,
} from './interface'

const actionCreator = actionCreatorFactory('USER')

export const Actions = {
  signIn: actionCreator.async<SignInActionInput, SignInActionOutput>(SIGN_IN),

  signOut: actionCreator.async<SignOutActionInput, {}>(SIGN_OUT),
  getCurrentUser: actionCreator.async<{}, getCurrentUserOutput>(
    GET_CURRENT_USER
  ),
  updateCurrentUser: actionCreator.async<UpdateCurrentUserInput, {}>(UPDATE_CURRENT_USER),
  restore: actionCreator.async<
    {
      email: string
      successCallback: (email: string) => void
      errorCallback: (error: Error) => void
    },
    {}
  >('RESTORE'),
  resetPassword: actionCreator.async<
    {
      data: IResetPassword
      successCallback: (email: string) => void
      errorCallback: (error: Error) => void
    },
    {}
  >('RESET_PASSWORD'),
}
