import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { People, Settings } from '@material-ui/icons'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { SnackbarContext } from '../components/Snackbar'
import { PATHS } from '@/constants'

interface Props {
  signOut: any
}

const Sidebar: React.FC<Props & RouteComponentProps> = ({ children, signOut, location, ...props }) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const handleSignOut = () => {
    const callback = () => openSnackbar({ type: 'success', message: 'ログアウトしました' })
    signOut({ callback })
  }

  const renderNavItem = (path: string, icon: React.ReactElement, label: string) => {
    const isActive = path === location.pathname

    return (
      <li className={`
        p-2 text-sm rounded
        ${isActive && 'bg-primary-light text-primary'}
      `}>
        <Link className="flex items-center" to={path}>
          <span className={isActive ? 'text-primary' : 'text-gray-700'}>{icon}</span>
          <span className="inline-block ml-2">{label}</span>
        </Link>
      </li>
    )
  }

  return (
    <div className="flex w-full">
      <div className="relative w-64 h-screen shadow bg-white divide-y divide-gray-400">
        <div className="p-4">
          <img className="mt-6" src="/assets/avatar.svg" alt=""/>
          <h1 className="mt-6 text-2xl">BeDo+ 管理画面</h1>
        </div>
        <div className="p-4">
          <ul>
            {renderNavItem(PATHS.DASHBOARD, <People/>, 'ユーザー管理')}
          </ul>
        </div>
        <div className="p-4">
          <h2 className="text-sm text-gray-500">設定</h2>
          <ul>
            {renderNavItem(PATHS.SETTINGS, <Settings/>, '管理者情報')}
          </ul>
        </div>
        <div className="p-4">
          <Button
            className="w-full"
            onClick={handleSignOut}
            variant="outlined"
            color="primary"
          >
            サインアウト
          </Button>
        </div>
      </div>
      <div className="flex-auto max-h-screen overflow-scroll">
        {children}
      </div>
    </div>
  )
}

export default withRouter(Sidebar)
