import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { filter, map, catchError, exhaustMap } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import * as logsActions from './actions'
import { RootState } from '../reducers'
import { getLogs, getLog, createLog, updateLog } from './api'

type Action = ActionType<typeof logsActions>

const getLogsEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(logsActions.getLogs.started.match),
  exhaustMap(({ payload }) => from(getLogs({ username: payload.userId })).pipe(
    map((response: any) => {
      const { logs, nextToken } = response

      return logsActions.getLogs.done({
        params: payload,
        result: { logs, nextToken }
      })
    }),
    catchError((error) => {
      return of(logsActions.getLogs.failed({
        params: payload,
        error,
      }))
    })
  )),
)

const getLogEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(logsActions.getLog.started.match),
  exhaustMap(({ payload }) => from(getLog({ id: payload.id })).pipe(
    map((response: any) => {
      const { log } = response

      return logsActions.getLog.done({
        params: payload,
        result: { log }
      })
    }),
    catchError((error) => {
      return of(logsActions.getLog.failed({
        params: payload,
        error,
      }))
    })
  )),
)

const createLogEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(logsActions.createLog.started.match),
  exhaustMap(({ payload }) => from(createLog(payload)).pipe(
    map((response: any) => {
      const { log } = response
      payload.callbacks.success()

      return logsActions.createLog.done({
        params: payload,
        result: { log }
      })
    }),
    catchError((error) => {
      payload.callbacks.error()
      return of(logsActions.createLog.failed({
        params: payload,
        error,
      }))
    })
  )),
)

const updateLogEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(logsActions.updateLog.started.match),
  exhaustMap(({ payload }) => from(updateLog(payload)).pipe(
    map((response: any) => {
      const { log } = response
      payload.callbacks.success()

      return logsActions.updateLog.done({
        params: payload,
        result: { log }
      })
    }),
    catchError((error) => {
      payload.callbacks.error()
      return of(logsActions.updateLog.failed({
        params: payload,
        error,
      }))
    })
  )),
)

export default combineEpics(
  getLogsEpic,
  getLogEpic,
  createLogEpic,
  updateLogEpic
)


