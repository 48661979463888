import React from 'react'
import './styles/tailwind.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import Sidebar from './layouts/Sidebar'
import SignIn from './pages/SignIn'
import Restore from './pages/signin/restore.component'
import ResetPassword from './pages/signin/resetPassword.component'
import Users from './pages/Users.connect'
import Logs from './pages/Logs.connect'
import Settings from './pages/Settings.connect'
import LogEdit from './pages/LogEdit.connect'
import { PATHS } from './constants'
import PrivateRoute from './components/PrivateRoute'
import SnackbarProvider from './components/Snackbar'
import { ContainerProps } from './App.connect'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6900CC',
    },
  },
})

const App: React.FC<ContainerProps> = ({
  signIn,
  signOut,
  getCurrentUser,
  user,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App h-screen">
        <div className="flex items-center h-full">
          <SnackbarProvider>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path={PATHS.SIGN_IN}
                  component={() => <SignIn user={user} signIn={signIn} />}
                />
                <Route exact path={PATHS.RESTORE} component={Restore} />
                <Route
                  exact
                  path={PATHS.RESET_PASSWORD}
                  component={ResetPassword}
                />

                <PrivateRoute user={user} getCurrentUser={getCurrentUser}>
                  <Sidebar signOut={signOut}>
                    <Route exact path={PATHS.DASHBOARD} component={Users} />
                    <Route exact path={PATHS.LOG_INDEX} component={Logs} />
                    <Route exact path={PATHS.LOG_EDIT} component={LogEdit} />
                    <Route exact path={PATHS.LOG_CREATE} component={LogEdit} />
                    <Route exact path={PATHS.SETTINGS} component={Settings} />
                  </Sidebar>
                </PrivateRoute>
                {/* <Route path="*" component={NotFoundPage} /> */}
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
