import { combineEpics } from 'redux-observable'
import userEpic from './user/epic'
import usersEpic from './users/epic'
import logsEpic from './logs/epic'

const rootEpic = combineEpics(
  userEpic,
  usersEpic,
  logsEpic,
)

export default rootEpic