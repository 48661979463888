import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App.connect'
import Amplify from 'aws-amplify'
import * as serviceWorker from './serviceWorker'
import { store } from './stores'

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_URL,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
  },
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
