export const QueryGetLogs = `query GetForecastListByUser($username: ID!) {
  getForecastListByUser(username: $username) {
    items {
      id
      username
      weather
      reason
      value
      isFavorite
      settedAt
      createdAt
      updatedAt
    }
    nextToken
  }
}`

export const QueryGetLog = `query GetForecast($id: ID!) {
  getForecast(id: $id) {
    id
    username
    weather
    reason
    value
    isFavorite
    settedAt
    createdAt
    updatedAt
  }
}`

export const MutationCreateLog = `mutation CreateForecast($input: ForecastCreateInput!) {
  createForecast(input: $input) {
    id
    username
    weather
    value
    reason
    isFavorite
    settedAt
    createdAt
    updatedAt
  }
}`

export const MutationUpdateLog = `mutation UpdateForecast($input: ForecastUpdateInput!) {
  updateForecast(input: $input) {
    id
    username
    weather
    value
    reason
    isFavorite
    settedAt
    createdAt
    updatedAt
  }
}`

export const MutationDeleteLog = `mutation DeleteForecast($id: ID!) {
  deleteForecast(id: $id) {
    id
    username
    weather
    value
    reason
    isFavorite
    settedAt
    createdAt
    updatedAt
  }
}`
