import { Dispatch } from 'redux'
import * as LogsActions from '../stores/logs/actions'
import * as UsersActions from '../stores/users/actions'
import { RootState } from '../stores/reducers'
import { connect, ConnectedProps } from 'react-redux'
import LogEdit from './LogEdit'
import { CreateLogInput, UpdateLogInput } from '../stores/logs/interface'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getLog: (id: string) => dispatch(LogsActions.getLog.started({ id })),
  createLog: (input: CreateLogInput) => dispatch(LogsActions.createLog.started(input)),
  updateLog: (input: UpdateLogInput) => dispatch(LogsActions.updateLog.started(input)),
})

const mapStateToProps = (state: RootState) => ({
  logs: { ...state.logs.data },
  users: [...state.users.data],
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ContainerProps = ConnectedProps<typeof connector>

export default connect(mapStateToProps, mapDispatchToProps)(LogEdit)
