import React, { useEffect, useContext } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { PATHS } from '../constants'
import { SnackbarContext } from '../components/Snackbar'

type Inputs = {
  email: string
  password: string
}

interface Props {
  user: any
  signIn: any
}

const SignIn: React.FC<RouteComponentProps & Props> = ({
  user,
  signIn,
  history,
}) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const { register, handleSubmit, errors } = useForm<Inputs>()
  const onSubmit = async (data: Inputs) => {
    const callbacks = {
      success: () =>
        openSnackbar({ type: 'success', message: 'ログインしました' }),
      error: () =>
        openSnackbar({
          type: 'error',
          message: 'メールアドレスまたはパスワードが違います',
        }),
    }
    signIn({ user: data, callbacks })
  }

  // Redirect
  useEffect(() => {
    if (user.data) {
      history.push(PATHS.DASHBOARD)
    }
  }, [user])

  return (
    <div className="w-full max-w-sm m-auto pt-6 pb-12 px-8 shadow-colored">
      <img className="m-auto" src="/assets/logo.svg" alt="" />
      <h1 className="text-2xl text-center">BeDo+ 管理画面</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6">
          <TextField
            name="email"
            type="email"
            inputRef={register({
              required: 'メールアドレスを入力してください',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: '無効なメールアドレスです',
              },
            })}
            className="w-full"
            label="メールアドレス"
            helperText={!!errors.email && errors.email.message}
            error={!!errors.email}
          />
        </div>
        <div className="mt-6">
          <TextField
            name="password"
            inputRef={register({
              required: 'パスワードが入力されていません',
              pattern: {
                value: /^(?=.*?[a-z])[a-z\d]{8,24}$/i,
                message: '半角英数字8〜24文字で入力してください',
              },
            })}
            className="w-full"
            label="パスワード"
            type="password"
            helperText={!!errors.password && errors.password.message}
            error={!!errors.password}
          />
          <Link
            className="inline-block text-xs mt-2 text-primary"
            to={PATHS.RESTORE}
          >
            パスワードをお忘れの場合
          </Link>
        </div>
        <div className="mt-6">
          <Button
            type="submit"
            className="w-full"
            variant="contained"
            color="primary"
          >
            ログイン
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withRouter(SignIn)
