import React, { useContext } from 'react'
import {
  TextField,
  Button,
} from '@material-ui/core'
import { ContainerProps } from './Settings.connect'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from '@/components/Snackbar'

type Inputs = {
  email: string,
}

const Settings: React.FC<ContainerProps> = ({ user, updateCurrentUser }) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const { register, handleSubmit, errors } = useForm<Inputs>()
  const onSubmit = async (data: Inputs) => {
    if (user.data?.email === data.email) {
      openSnackbar({ type: 'error', message: '現在の登録されているメールアドレスと同じです' })
      return
    }

    const callbacks = {
      success: () => openSnackbar({ type: 'success', message: '管理者情報を更新しました' }),
      error: () => openSnackbar({ type: 'error', message: 'エラーが発生しました' }),
    }
    updateCurrentUser({ attributes: { email: data.email }, callbacks })
  }

  return (
    <div>
      <div className="flex justify-between items-center p-12 bg-white shadow">
        <h2 className="text-2xl">管理者情報</h2>
      </div>
      <div className="p-8">
        <div className="w-full max-w-4xl m-auto pt-6 pb-12 px-8 bg-white shadow rounded">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6">
              <TextField
                name="email"
                type="email"
                inputRef={register({
                  required: 'メールアドレスを入力してください',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: '無効なメールアドレスです',
                  },
                })}
                className="w-full"
                label="メールアドレス"
                placeholder="bedo@gmail.com"
                defaultValue={user.data?.email}
                helperText={!!errors.email && errors.email.message}
                error={!!errors.email}
              />
            </div>
            <div className="flex justify-end mt-10">
              <Button variant="contained" color="primary" type="submit">
                この内容で登録
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Settings
