export const SIGN_IN = '/sign_in'
export const SIGN_UP = '/sign_up'
export const RESTORE = '/restore'
export const RESET_PASSWORD = '/reset_password'

export const DASHBOARD = '/dashboard'
export const SETTINGS = '/settings'
export const LOG_INDEX = '/user/:userId/logs'
export const LOG_CREATE = '/user/:userId/log/create'
export const LOG_EDIT = '/user/:userId/log/:logId/edit'
