import { Dispatch } from 'redux'
import * as UsersActions from '../stores/users/actions'
import { RootState } from '../stores/reducers'
import { connect, ConnectedProps } from 'react-redux'
import Users from './Users'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUsers: ({ after = null }: { after: null | string }) => dispatch(UsersActions.getUsers.started({ 
after })),
  searchUsers: (input: string) => dispatch(UsersActions.searchUsers.started({ input })),
})

const mapStateToProps = (state: RootState) => ({
  users: { ...state.users },
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ContainerProps = ConnectedProps<typeof connector>

export default connect(mapStateToProps, mapDispatchToProps)(Users)
