import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { filter, map, catchError, exhaustMap } from 'rxjs/operators'
import { ActionType } from 'typesafe-actions'
import * as usersActions from './actions'
import { RootState } from '../reducers'
import { getUsers } from './api'

type Action = ActionType<typeof usersActions>

const LIMIT = 10

const getUsersEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(usersActions.getUsers.started.match),
  exhaustMap(({ payload }) => from(getUsers({ first: LIMIT, after: payload.after })).pipe(
    map((response) => {
      return usersActions.getUsers.done({
        params: payload,
        result: {
          users: response.users,
          nextToken: response.nextToken,
        }
      })
    }),
    catchError((error) => {
      return of(usersActions.getUsers.failed({
        params: payload,
        error,
      }))
    })
  )),
)

const searchUsersEpic: Epic<Action, Action, RootState> = action$ => action$.pipe(
  filter(usersActions.searchUsers.started.match),
  exhaustMap(({ payload }) => ajax.getJSON(
    // TODO
    'https://jsonplaceholder.typicode.com/users'
  ).pipe(
    map((response: any) => {
      const data = response.map((user: any) => ({
        id: user.id,
        email: user.email,
        nickname: user.username
      }))
      return usersActions.searchUsers.done({
        params: payload,
        result: { users: data }
      })
    }),
    catchError((error) => {
      return of(usersActions.searchUsers.failed({
        params: payload,
        error,
      }))
    })
  )),
)

export default combineEpics(
  getUsersEpic,
  searchUsersEpic
)


