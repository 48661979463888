import 'date-fns'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  CircularProgress
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { WEATHERS } from '../constants'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { ContainerProps } from './LogEdit.connect'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { getLog, deleteLog } from '@/stores/logs/api'
import { Log } from '@/stores/logs/interface'
import { User } from '@/stores/users/interface'
import { SnackbarContext } from '@/components/Snackbar'
import { getUser } from '@/stores/users/api'

type Inputs = {
  reason: string,
  weather: string,
}

const WEATHER_OPTIONS = Object.values(WEATHERS)

const LogEdit: React.FC<ContainerProps & RouteComponentProps<{ userId: string, logId?:string }>> = ({
  match,
  history,
  users,
  createLog,
  updateLog,
}) => {
  const { openSnackbar } = useContext(SnackbarContext)
  const userId = useMemo(() => match.params.userId, [match])
  const logId = useMemo(() => match.params.logId, [match])

  const [user, setUser] = useState<User | null>(null)
  const [log, setLog] = useState<Log | null>(null)
  const isFetching = useMemo(() => logId && (log === null || user === null), [logId, log, user])

  const fetchLog = async (id: string) => {
    const fetched = await getLog({ id })
    setLog(fetched.log)
    setDate(new Date(fetched.log.settedAt))
    setWeather(fetched.log.weather)
    setValue(fetched.log.value)
  }

  const fetchUser = async (id: string) => {
    const fetched = await getUser({ id: userId })
    setUser(fetched.user)
  }

  useEffect(() => {
    if (logId) {
      fetchLog(logId)
    }
    if (userId) {
      fetchUser(userId)
    }
  }, [])

  const [value, setValue] = useState<number>(50)
  const [date, setDate] = useState<Date>(new Date())
  const handleDateChange = (date: any) => {
    setDate(date)
  }
  const [weather, setWeather] = React.useState('')
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWeather(event.target.value as string)
  }

  const { register, handleSubmit, errors } = useForm<Inputs>()
  const onSubmit = (data: any) => {
    const { reason } = data

    if (logId) {
      // Update
      const input = {
        id: logId,
        reason,
        weather,
        value,
        settedAt: dayjs(date).format('YYYY/MM/DD HH:mm'),
      }
      const callbacks = {
        success: () => {
          openSnackbar({ type: 'success', message: 'ログを編集しました' })
          history.push(`/user/${userId}/logs`)
        },
        error: () => {
          openSnackbar({ type: 'error', message: 'エラーが発生しました' })
        },
      }
      updateLog({ input, callbacks })
    } else {
      // Create
      const input = {
        username: userId,
        reason,
        weather,
        value,
        settedAt: dayjs(date).format('YYYY/MM/DD HH:mm'),
      }
      const callbacks = {
        success: () => {
          openSnackbar({ type: 'success', message: 'ログを追加しました' })
          history.push(`/user/${userId}/logs`)
        },
        error: () => {
          openSnackbar({ type: 'error', message: 'エラーが発生しました' })
        },
      }
      createLog({ input, callbacks })
    }
  }

  const handleDelete = () => {
    try {
      deleteLog({ id: logId as string })
      openSnackbar({ type: 'success', message: 'ログを削除しました' })
      history.push(`/user/${userId}/logs`)
    } catch (error) {
      console.error(error)
      openSnackbar({ type: 'error', message: 'エラーが発生しました' })
    }
  }

  const renderProgress = () => (
    <div className="flex justify-center p-10">
      <CircularProgress/>
    </div>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl">{logId ? 'ログ編集' : '新規ログ登録'}</h2>
      <div className="mt-6">
        <KeyboardDatePicker
          className="w-56"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label="日付を選択"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>
      <div className="mt-6">
        <KeyboardTimePicker
          className="w-56"
          margin="normal"
          label="時間を選択"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{ 'aria-label': 'change time' }}
        />
      </div>
      <div className="mt-6">
        <FormControl className="w-full">
          <InputLabel htmlFor="age-native-simple">
            天気を選択
          </InputLabel>
          <Select
            name="weather"
            value={weather}
            onChange={handleChange}
            displayEmpty
          >
            {WEATHER_OPTIONS.map(({ value, label, color }) => (
              <MenuItem key={value} value={value}>
                <span>{label}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-6">
        <InputLabel shrink htmlFor="discrete-slider">
          ウェルビーイング（幸福）指数
        </InputLabel>
        <div className="mt-10">
          <Slider
            name="value"
            value={value}
            onChange={(e, v) => setValue(v as number)}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            marks
            min={0}
            max={100}
          />
        </div>
      </div>
      <div className="mt-6">
        <TextField
          name="reason"
          className="w-full"
          label="天気と幸福指数の理由"
          placeholder="理由を入力"
          multiline
          rows={4}
          defaultValue={log && log?.reason}
          inputRef={register({
            required: '天気と幸福指数の理由を入力してください',
          })}
        />
      </div>
      <div className="flex justify-end mt-10 gap-4">
        {logId &&
          <Button onClick={handleDelete} color="primary">
            削除
          </Button>
        }
        <Button variant="contained" color="primary" type="submit">
          この内容で登録
        </Button>
      </div>
    </form>
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="flex justify-between items-center p-12 bg-white shadow">
        <h2 className="text-2xl">{user?.nickname}</h2>
        <Link className="text-sm text-primary" to={`/user/${userId}/logs`}>ログ一覧へ戻る</Link>
      </div>
      <div className="p-8">
        <div className="w-full max-w-4xl m-auto pt-6 pb-12 px-8 bg-white shadow rounded">
          {isFetching ? renderProgress() : renderForm()}
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(LogEdit)
