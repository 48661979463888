import { combineReducers } from 'redux'
import { userReducer } from './user/reducer'
import { usersReducer } from './users/reducer'
import { logsReducer } from './logs/reducer'

const reducers = combineReducers({
  user: userReducer,
  users: usersReducer,
  logs: logsReducer,
})

export type RootState = ReturnType<typeof reducers>

export default reducers