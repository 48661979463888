import { Dispatch } from 'redux'
import * as LogsActions from '../stores/logs/actions'
import * as UsersActions from '../stores/users/actions'
import { RootState } from '../stores/reducers'
import { connect, ConnectedProps } from 'react-redux'
import Logs from './Logs'

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getLogs: (userId: string) => dispatch(LogsActions.getLogs.started({ userId })),
  // TODO getUserById
  getUsers: ({ after = null }: { after: null | string }) => dispatch(UsersActions.getUsers.started({ after })),
})

const mapStateToProps = (state: RootState) => ({
  logs: { ...state.logs },
  users: [...state.users.data],
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ContainerProps = ConnectedProps<typeof connector>

export default connect(mapStateToProps, mapDispatchToProps)(Logs)
