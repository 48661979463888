import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as userActions from './actions'
import { UsersState } from './interface'
import { uniqBy } from 'lodash-es'

const initialState: UsersState = {
  data: [],
  fetching: false,
  nextToken: null,
  error: null,
}

export const usersReducer = reducerWithInitialState<UsersState>(initialState)
  // Get users
  .case(userActions.getUsers.started, (state, payload) => {
    const data = payload.after === null ? [] : state.data
    return { ...state, data, fetching: true, error: null }
  })
  .case(userActions.getUsers.done, (state, payload) => {
    const { users, nextToken } = payload.result
    const updatedUsers = uniqBy([...state.data, ...users], 'id')
    return { ...state, fetching: false, data: updatedUsers, nextToken: nextToken, error: null }
  })
  .case(userActions.getUsers.failed, (state, payload) => {
    return { ...state, fetching: false, error: { ...payload.error } }
  })
