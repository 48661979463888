import React, { useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PATHS } from '../constants'
import { UserState } from '../stores/user/interface'

interface Props {
  user: UserState
  getCurrentUser: any
}

const PrivateRoute: React.FC<RouteComponentProps & Props> = ({
  user,
  getCurrentUser,
  history,
  children,
}) => {
  useEffect(() => {
    getCurrentUser()
  }, [])

  useEffect(() => {
    if (
      // Fetch failed
      user.error ||
      // Sign out
      (user.fetched && !user.error && !user.data)
    ) {
      if (
        // Auth unnecessary
        // 別の方法ないか
        [PATHS.RESTORE, PATHS.RESET_PASSWORD].includes(window.location.pathname)
      ) {
        return
      }
      history.push(PATHS.SIGN_IN)
    }
  }, [user])

  return user.data ? <>{children}</> : null
}

export default withRouter(PrivateRoute)
